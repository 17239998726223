import { Link } from 'react-router-dom';
import { Row, Col, Image, NavDropdown, Navbar, Nav } from 'react-bootstrap';
import logouusi_esl from '../../resources/images/logouusi_esl.png';
import './NavBar.css';
import React, { useEffect, useState, useCallback } from 'react';
import { FaYoutube, FaInstagram, FaTwitterSquare, FaTiktok, FaTelegram } from 'react-icons/fa';
import { MatchMap } from '../../utils/MatchMap.js';
import Paginator from './Paginator';
import _ from 'lodash';

const NavBar = ({ profiles, matchCollection, teamsCollection }) => {
  const [mappedProfiles, setMappedProfiles] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleScroll = () => {
    if (collapsed) {
      setCollapsed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [collapsed]);

  const handleToggle = useCallback(() => {
    setCollapsed(prevCollapsed => !prevCollapsed);
  }, []);

  useEffect(() => {
    const mapProfiles = teamsCollection => {
      if (!teamsCollection) return;

      return (
        <>
          {streamerProfiles.length > 0 && (
            <NavDropdown title="Sometalli" id="nav-dropdown-streamers" menuVariant="dark" className="nav-item">
              {streamerProfiles.map(item =>
                item.profile ? (
                  <NavDropdown.Item
                    key={item.profile.nickname}
                    as={Link}
                    onClick={handleToggle}
                    to={`/profile/${item.profile.nickname}`}
                    className="nav-item"
                  >
                    {item.profile.nickname}
                  </NavDropdown.Item>
                ) : (
                  item
                )
              )}
            </NavDropdown>
          )}
          {teamsCollection.map(team => {
            // Extract the team name or urlPrefix as the category for the team
            const teamName = team.game;

            return (
              <NavDropdown
                key={teamName}
                title={teamName}
                id={`nav-dropdown-${teamName}`}
                menuVariant="dark"
                className="nav-item"
              >
                {/* Map profiles for each team */}
                {team.membersCollection.items.map(item =>
                  item.profile ? (
                    <NavDropdown.Item
                      key={item.profile.nickname}
                      as={Link}
                      onClick={handleToggle}
                      to={`/profile/${item.profile.nickname}`}
                      className="nav-item"
                    >
                      {item.profile.nickname}
                    </NavDropdown.Item>
                  ) : (
                    item
                  )
                )}
              </NavDropdown>
            );
          })}
        </>
      );
    };

    const [streamerProfiles, normalProfiles] = _.partition(profiles, ({ isStreamer }) => isStreamer);

    setMappedProfiles(mapProfiles(teamsCollection));
  }, [profiles, handleToggle]);

  useEffect(() => {
    // Function to update the isMobile state
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="overflow-x-disable w-100 nav-row">
      <div className="container">
        <Row>
          <Row>
            <div className="d-flex flex-row-reverse gap-2 px-md-5">
              <a href={'https://twitter.com/northern_legacy'} target="_blank" rel="noreferrer">
                <FaTwitterSquare />
              </a>
              <a href={'https://www.instagram.com/northernlegacyesports'} target="_blank" rel="noreferrer">
                <FaInstagram />
              </a>
              <a href="https://www.youtube.com/@NLEFI" target="_blank" rel="noreferrer">
                <FaYoutube />
              </a>
              <a href={'https://www.tiktok.com/@nlefi.gg?_t=8ghmOqLvwXp&_r=1'} target="_blank" rel="noreferrer">
                <FaTiktok />
              </a>
              <a href={'https://t.me/NLE_TG'} target="_blank" rel="noreferrer">
                <FaTelegram />
              </a>
            </div>
          </Row>
          <Row>
            <Col xs={{ span: 4, order: 2 }} sm={{ span: 2, order: 1 }}>
              <Nav.Link as={Link} to="/">
                <Image className="nav-logo" src={logouusi_esl} />
              </Nav.Link>
            </Col>
            <Col className="d-flex align-items-center" xs={{ span: 8, order: 1 }} sm={{ span: 10, order: 2 }}>
              <Navbar
                expanded={collapsed}
                onToggle={() => setCollapsed(!collapsed)}
                collapseOnSelect
                className="nav-bar"
                expand="md"
                bg="black"
                variant="dark"
              >
                <Navbar.Toggle aria-controls="responsive-navbar-nav" className="nav-item" />
                <Navbar.Collapse className="nav-item">
                  <Nav className="me-auto mw-100 justify-content-center w-100 gap-3">
                    <Nav.Link as={Link} onClick={handleToggle} to="/">
                      Koti
                    </Nav.Link>
                    <Nav.Link as={Link} onClick={handleToggle} to="/roster">
                      Some
                    </Nav.Link>
                    <Nav.Link as={Link} onClick={handleToggle} to="/esports">
                      Esports
                    </Nav.Link>
                    <NavDropdown title="Lauma" className="nav-item">
                      {mappedProfiles}
                    </NavDropdown>
                    <Nav.Link as={Link} onClick={handleToggle} to="/sponsors">
                      Yhteistyökumppanit
                    </Nav.Link>
                    <Nav.Link as={Link} onClick={handleToggle} to="/marketing">
                      NLE markkinoi
                    </Nav.Link>
                    <Nav.Link as={Link} onClick={handleToggle} to="/info">
                      NLE who?
                    </Nav.Link>
                    <Nav.Link
                      onClick={handleToggle}
                      href="https://www.nleshop.fi"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      NLESHOP
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </Col>
          </Row>
        </Row>
        <Row>
          <Paginator
            data={matchCollection}
            formatfunc={MatchMap}
            classStyle="text-center match-container mt-2"
            hidePage
            size={isMobile ? 1 : 3}
            useScroll={false}
          />
        </Row>
      </div>
    </div>
  );
};

export default NavBar;
