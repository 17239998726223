import React from 'react';
import { Container } from 'react-bootstrap';

const PrivacyPolicy = () => {
  return (
    <Container>
      <>Privacy Policy Effective Date: 18.3.2025</>
      <h2>1. Introduction</h2>
      <p>
        Welcome to Atk Apa ("Company," "we," "our," "us"). Your privacy is important to us, and we are committed to
        protecting your personal data. This Privacy Policy explains how we collect, use, disclose, and safeguard your
        information when you visit our website or use our services.
      </p>

      <h2>2. Company Information</h2>
      <ul>
        <li>
          <strong>Company Name:</strong> ATk Apa
        </li>
        <li>
          <strong>VAT Number:</strong> 3085280-2
        </li>
        <li>
          <strong>Address:</strong> Kuninkaankatu 27b11
        </li>
        <li>
          <strong>Contact Email:</strong> mikkomyllari@gmail.com
        </li>
      </ul>

      <h2>3. Information We Collect</h2>
      <p>We dont collect any personal data</p>

      <h2>4. Changes to This Privacy Policy</h2>
      <p>
        We may update this policy from time to time. Any changes will be posted on this page with an updated effective
        date.
      </p>

      <h2>5. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at:</p>
      <p>
        Atk Apa <br /> mikkomyllari@gmail.com <br />
      </p>
    </Container>
  );
};

export default PrivacyPolicy;
